import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'
import MapPin from '../../../assets/icons/map-pin.svg'

export const LocationBarContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: ${props => props.theme.style.alignItems.center};
    padding: ${props => `${props.theme.spacing.xxSmall} 0`};
    border-top: 1px dotted ${props => props.theme.colors.tfwBlackOpacity10};
    border-bottom: 1px dotted ${props => props.theme.colors.tfwBlackOpacity10};
    gap: ${({ theme }) => theme.spacing.xSmall};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${props => `${props.theme.spacing.xSmall} 0`};
    }
`

export const MapPinIcon = styled(MapPin).attrs({ as: MapPin })`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`

export const StyledLink = styled.a`
    text-decoration: none;
`

export const MapAnchor = styled.div`
    text-decoration: none;
`
