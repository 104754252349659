import React, { memo } from 'react'
import { CalendarWrapper, WeekRow, DayBox, EmptyDayBox, DayHeaderCell } from './styles'
import { useTranslation } from '../../../../hooks/locale'
import { Typography } from 'react-components'
import { MonthData } from '../../../../transports/property-details-page-transport'

type AvailabilityCalendarProps = {
    data: MonthData
}

const MonthCalendar: React.FC<AvailabilityCalendarProps> = ({ data }) => {
    const { t } = useTranslation()

    const getDayName = (dayKey: string, short = false) => {
        const translation = t(dayKey, { style: short ? 'short' : 'long' })
        return translation
    }

    const dayNames = [
        getDayName('dayNames.monday', true),
        getDayName('dayNames.tuesday', true),
        getDayName('dayNames.wednesday', true),
        getDayName('dayNames.thursday', true),
        getDayName('dayNames.friday', true),
        getDayName('dayNames.saturday', true),
        getDayName('dayNames.sunday', true),
    ]

    return (
        <CalendarWrapper>
            <Typography variant="subtitle1" fontWeight="highlight">
                {data.label} {data.year}
            </Typography>
            <WeekRow>
                {dayNames.map((day, index) => (
                    <DayBox key={index}>
                        <DayHeaderCell key={index}>{day}</DayHeaderCell>
                    </DayBox>
                ))}
            </WeekRow>
            {data.rows.map((week, weekIndex) => (
                <WeekRow key={weekIndex}>
                    {week.map((day, dayIndex) =>
                        day.value ? (
                            <DayBox key={dayIndex} state={day.state}>
                                <Typography variant="subtitle2" fontWeight="regular" color="white">
                                    {day.value}
                                </Typography>
                            </DayBox>
                        ) : (
                            <EmptyDayBox key={dayIndex} />
                        ),
                    )}
                </WeekRow>
            ))}
        </CalendarWrapper>
    )
}

export default memo(MonthCalendar)
