/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, memo, useMemo, useEffect } from 'react'
import { PageProps, ResponseLocals } from '../../common/types'
import { GetServerSideProps } from '../../redux/types'
import { getLocaleFromLanguage, getMessagesFromLanguage } from '../../i18n/utils'
import { deleteSession } from '../../utils/delete-session'
import Head from 'next/head'

import { getRoutesFromDomain } from '../../routes/utils'
import { BaseTransport } from '../../transports'

import { useUserDetails } from '../../hooks/users'
import { useTranslation } from '../../hooks/locale'
import Page from '../../components/common/page'
import PropertyDetailsPage from '../../components/property-details'
import { pageClient } from '../../clients'
import PropertyDetailsPageTransport, {
    PropertyDetailsPageParamsTransport,
} from '../../transports/property-details-page-transport'
import { encodeToBase64 } from '../../utils/encoding-utils'
import { PropertyDetails } from '../../models/property-details-page/property-details-model'
import { useRouting } from '../../hooks/routes'
import { useDateFormatter } from '../../hooks/date'
import usePriceFormatter from '../../hooks/price'
import { getProductSchemaForProperty, getVacationRentalSchemaForProperty } from '../../schema/property-details-page'
import AnalyticsService from '../../services/analytics-service'
import { pdpPageCategories } from '../../services/analytics-service/categories'
import { pdpPageActions } from '../../services/analytics-service/actions'

type Props = {
    sitePathname: string
    fullUrl: string
    locale: string
    data: BaseTransport<PropertyDetailsPageTransport>
    listingId: string
} & PageProps

const PropertyDetailsPageContainer: FC<Props> = ({ sitePathname, fullUrl, data, listingId }) => {
    const propertyData: PropertyDetailsPageTransport | null = data?.data
    const { userDetails } = useUserDetails({ additionalDetails: true })
    const isLoggedIn = !!userDetails?.id
    const { t } = useTranslation()
    const { format } = useDateFormatter()
    const { r } = useRouting()
    const priceFormatter = usePriceFormatter()
    const propertyDetails = useMemo(() => {
        if (propertyData) {
            return new PropertyDetails(
                t,
                r,
                format,
                priceFormatter,
                propertyData.expose,
                propertyData.customerObjects,
                propertyData.breadcrumbs,
                propertyData.object,
            ).getData()
        }
        return null
    }, [format, propertyData, r, t, priceFormatter])

    const vacationRentalSchemaForProperty = useMemo(
        () =>
            getVacationRentalSchemaForProperty({
                propertyDetails,
                vacationRegionNode: propertyData?.vacationRegionNode,
                objectDetails: propertyData?.object,
                rooms: propertyData?.expose.objectRooms,
                prices: propertyData?.price,
                t,
            }),
        [propertyData, t],
    )

    const productSchemaForProperty = useMemo(
        () =>
            getProductSchemaForProperty({
                propertyDetails,
                prices: propertyData?.price,
            }),
        [propertyData],
    )

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            AnalyticsService.trackEvent({
                category: pdpPageCategories.PDP_PAGE,
                action: pdpPageActions.PDP_PAGE_OPENED,
            })
        }, 0)

        return () => clearTimeout(timeoutId)
    }, [])

    if (!propertyData) {
        return null
    }

    return (
        <Page head={propertyData.head}>
            {(!!vacationRentalSchemaForProperty || !!productSchemaForProperty) && (
                <Head>
                    {!!vacationRentalSchemaForProperty && (
                        <script type="application/ld+json" dangerouslySetInnerHTML={vacationRentalSchemaForProperty} />
                    )}
                    {!!productSchemaForProperty && (
                        <script type="application/ld+json" dangerouslySetInnerHTML={productSchemaForProperty} />
                    )}
                </Head>
            )}
            <PropertyDetailsPage
                userDetails={userDetails}
                isLoggedIn={isLoggedIn}
                sitePathname={sitePathname}
                url={fullUrl}
                propertyDetails={propertyDetails}
                availabilityCalendar={propertyData?.availabilityCalendar}
                navigationSection={propertyData.staticData.navigationSection}
                footerSection={propertyData.staticData.footerSection}
                regionNode={propertyData.regionNode}
                listingId={listingId}
            />
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ req, res, query }) => {
    const { user, device } = res.locals as ResponseLocals
    const sitePathname = req.path
    const requestUserConfig = deleteSession(user)
    const protocol = req.header('x-forwarded-proto') || req.protocol || 'http'
    const host = req.headers['host']
    const fullUrl = `${protocol}://${host}${sitePathname}`
    const locale = getLocaleFromLanguage(user.language)
    const { listingId } = req.params as unknown as PropertyDetailsPageParamsTransport
    const preview = !!query.preview
    const params: PropertyDetailsPageParamsTransport = {
        filter: encodeToBase64(req.originalUrl),
        preview,
        listingId,
    }

    const data = await pageClient.getPropertyDetailsPage({ user, params: params })
    if (data.status === 404) {
        return {
            notFound: true,
        }
    }
    return {
        props: {
            device,
            requestUserConfig: requestUserConfig,
            messages: await getMessagesFromLanguage(user.language),
            routes: await getRoutesFromDomain(user.domain),
            listingId,
            sitePathname,
            fullUrl,
            locale,
            data,
        },
    }
}

export default memo(PropertyDetailsPageContainer)
