import React, { useCallback } from 'react'
import { Typography } from 'react-components'
import { useTranslation } from '../../../hooks/locale'
import { SECTION_IDS } from '../constants'
import { scrollToSection } from '../navigation-bar'

const PriceCalculatorLink = () => {
    const { t } = useTranslation()

    const scrollToPriceSection = useCallback((): void => {
        scrollToSection(SECTION_IDS.PRICE_SECTION_ID)
    }, [])

    return (
        <span onClick={scrollToPriceSection}>
            <Typography variant="subtitle2" fontWeight="semiBold" element="span" textDecoration="underline">
                {t('price.calculator')}
            </Typography>
        </span>
    )
}

export default PriceCalculatorLink
