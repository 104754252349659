import React, { useContext, useState, useEffect, useMemo, memo } from 'react'
import MonthCalendar from './month-calendar'
import {
    AvailabilityCalendarWrapper,
    AvailabilityBoxWrapper,
    CalendarNavigationBar,
    DescriptionBar,
    LegendWrapper,
    Legend,
    LegendBar,
} from './styles'
import { useTranslation } from '../../../hooks/locale'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { ScreenContext, Typography } from 'react-components'
import AvailabilityCalendarBack from './availabily-calendar-back'
import AvailabilityCalendarNext from './availability-calendar-next'
import { ExposeSectionTitleWrapper, ComponentSeparator, StyledGhostButton, CTAButton } from '../style'
import { PropertyDetailsAvailabilityTransport } from '../../../transports/property-details-page-transport'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { SECTION_IDS } from '../constants'
import PriceCalculatorLink from './price-calculator-link'
import dynamic from 'next/dynamic'

const LazySelect = dynamic(() => import('react-components').then(mod => mod.Select), {
    ssr: false,
}) as typeof import('react-components').Select

const selectStyles = {
    container: (base: any) => ({
        ...base,
        width: '15rem',
        maxWidth: '80%',
    }),
}

type AvailabilityCalendarPropsTransport = PropertyDetailsAvailabilityTransport & { showCalendar: boolean }

const AvailabilityCalendar: React.FC<AvailabilityCalendarPropsTransport> = data => {
    const { t } = useTranslation()
    const { isMobile, isTablet } = useContext(ScreenContext)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [isMonthView, setMonthView] = useState(true)
    const monthsData = useMemo(() => (data?.result ? Object.values(data.result) : []), [data?.result])
    const firstAvailableMonth = get(data, 'firstAvailableMonth', '')
    const showAvailabilityCalendar = get(data, 'showCalendar', false)

    const toggleMonthView = () => setMonthView(!isMonthView)

    const onMonthSelect = (selectedOption: { label: string; value: number } | null): void => {
        if (selectedOption) {
            setCurrentSlide(selectedOption.value)
        }
    }

    const months = useMemo(() => {
        if (!isEmpty(data?.result)) {
            return Object.values(data.result)
                .map(month => ({
                    ...month,
                    year: Number(month.year),
                    monthIndex: Number(month.month),
                }))
                .sort((a, b) => new Date(a.year, a.month).getTime() - new Date(b.year, b.month).getTime())
                .map((month, index) => ({
                    label: `${month.label} ${month.year}`,
                    value: index,
                }))
        }
        return []
    }, [data?.result])

    useEffect(() => {
        if (!isEmpty(data?.result) && firstAvailableMonth) {
            const result = data.result
            const keys = Object.keys(result)
            keys.forEach((key, monthKeyIndex) => {
                if (key === firstAvailableMonth) {
                    setCurrentSlide(monthKeyIndex)
                    return
                }
            })
        }
    }, [data?.result, firstAvailableMonth])

    if (isEmpty(monthsData)) {
        return null
    }

    if (!showAvailabilityCalendar) {
        const message = t('availability.noCalendarMessage', {
            link: '<></>',
        })

        const parts = message.split('<></>')
        return (
            <section id={SECTION_IDS.AVAILABILITY_SECTION_ID}>
                <ExposeSectionTitleWrapper>
                    <Typography variant="h6" fontWeight="semiBold" element="h2">
                        {t('availabilityTitle')}
                    </Typography>
                </ExposeSectionTitleWrapper>
                <Typography variant="subtitle2" fontWeight="regular">
                    {parts[0]}
                    <PriceCalculatorLink />
                    {parts[1]}
                </Typography>
                <ComponentSeparator />
            </section>
        )
    }

    return (
        <section id={SECTION_IDS.AVAILABILITY_SECTION_ID}>
            <CarouselProvider
                totalSlides={monthsData.length}
                visibleSlides={isMobile || isTablet ? 1 : 2}
                naturalSlideWidth={1}
                naturalSlideHeight={1}
                isIntrinsicHeight
                lockOnWindowScroll
                dragStep={1}
                step={1}
                currentSlide={currentSlide}
            >
                <AvailabilityBoxWrapper>
                    <ExposeSectionTitleWrapper>
                        <Typography variant="h6" fontWeight="semiBold" element="h2">
                            {t('availabilityTitle')}
                        </Typography>
                    </ExposeSectionTitleWrapper>
                    <CalendarNavigationBar>
                        {isMonthView && <AvailabilityCalendarBack />}
                        <CTAButton onClick={toggleMonthView}>
                            <StyledGhostButton isPrimary={false} isContentWidth>
                                <Typography variant="subtitle2" fontWeight="regular">
                                    {isMonthView ? t('calendarViewTypeMonth') : t('calendarViewTypeYear')}
                                </Typography>
                            </StyledGhostButton>
                        </CTAButton>
                        {isMonthView && <AvailabilityCalendarNext numberOfSlides={monthsData.length} />}
                    </CalendarNavigationBar>
                    {isMonthView ? (
                        <Slider>
                            {monthsData.map((month, index) => (
                                <Slide key={index} index={index}>
                                    <MonthCalendar key={month?.label} data={month} />
                                </Slide>
                            ))}
                        </Slider>
                    ) : (
                        <AvailabilityCalendarWrapper>
                            {monthsData.map(month => (
                                <MonthCalendar key={month?.label} data={month} />
                            ))}
                        </AvailabilityCalendarWrapper>
                    )}
                    <DescriptionBar>
                        <LegendBar>
                            <Typography variant="subtitle1" fontWeight="highlight">
                                {t('legendTranslation')}:
                            </Typography>
                            <LegendWrapper>
                                <Legend available />
                                <Typography variant="subtitle2">{t('freeLabel')}</Typography>
                            </LegendWrapper>
                            <LegendWrapper>
                                <Legend available={false} />
                                <Typography variant="subtitle2">{t('occupiedLabel')}</Typography>
                            </LegendWrapper>
                        </LegendBar>
                        {isMonthView && (
                            <LazySelect
                                options={months}
                                onChange={onMonthSelect}
                                styles={selectStyles}
                                defaultValue={months[0]}
                            />
                        )}
                    </DescriptionBar>
                </AvailabilityBoxWrapper>
            </CarouselProvider>
            <ComponentSeparator />
        </section>
    )
}

export default memo(AvailabilityCalendar)
