import PageTransport from './common/page-transport'
import { CommunicationTypes } from '../models/property-details-page/contact-model'
import { OfferTypes } from '../models/property-details-page/offers-model'
import HeadTransport from './common/head-transport'
import NavigationSectionTransport from './common/navigation-section-transport'
import FooterSectionTransport from './common/footer-section-transport'
import { BreadcrumbTypeEnum } from '../common/enum'

export interface PropertyDetailsPageParamsTransport {
    filter: string
    preview?: boolean
    listingId: string
}
export interface PersonDetails {
    total: number
    adults: number
    children: number | null
}

export enum GeneralEquipmentsEnum {
    POOL = 'pool',
    TV = 'tv',
    SAUNA = 'sauna',
    WASHING_MACHINE = 'washingMachine',
    DISH_WASHER = 'dishwasher',
    WIFI = 'wifi',
}

export type GeneralEquipments = Record<GeneralEquipmentsEnum, boolean>

export interface Bedrooms {
    proper: number
    children: number
    living: number
    total: number
    miscellaneous: number
}

export interface AddressTransport {
    street: string
    houseNumber: string | null
    zipCode: string
    city: string
    country: string
    countryId: string | null
}

export interface SurroundingLocation {
    type: string
    distance: number | string
}

export interface BookingCancellationPolicyRule {
    daysFrom: number
    daysTo: number
    price: number | null
    percent: number
}

export interface BookingCancellationPolicy {
    id: number
    rules: BookingCancellationPolicyRule[]
}

export interface PropertyDetails {
    tfwObjectId: string
    objectKey: string
    status: number
    externalObjectId: string
    externalCustomerId: string | null
    listingUsercode: string | null
    hasAdvancedPricingFeature: boolean
    propertyTypeId: number
    squareMeters: number
    persons: PersonDetails
    regionIds: number[]
    regionNodeIds: number[]
    attractionIds: number[]
    generalEquipments: GeneralEquipments
    bedrooms: Bedrooms
    classification: string | null
    topicIds: number[]
    suitedIds: number[]
    featIds: number[]
    providerId: number
    customerId: number
    accessibility: number
    bookingType: number
    releaseDate: string
    lastUpdate: string
    objectOverviewLink: string
    locales: string[]
    showAvailabilityCalendar: boolean
    lastUpdateAvailability: string
    maxPets: number | null
    housingProtectionNumber: string | null
    onlineStatus: number
    showIncludedVatInformation: boolean
    activatedFreeCancellation: boolean
    freeCancellationInDays: number
    bookingCancellationPolicy: BookingCancellationPolicy | null
    traumPaymentsActivated: boolean
    entityId: string | null
    totalUnits: number | null
    isComboListing?: boolean
    isEntity?: boolean
}

export interface Communication {
    type: CommunicationTypes
    value: string
}

export interface CustomerDetails {
    title: number
    customerAlias: number
    lastName: string
    firstName: string
    email: string | null
    company: string
    communications: Communication[]
    address: string | null
    spokenLanguages: number[]
    imageId: string | null
    operatingHours: string | null
    introduction: string
    website: string | null
    imageUrl: string
}

export interface TopicDescription {
    key: string
    value: string
}

export interface PropertyOverview {
    title: string
    shortTitle: string
    surrounding: string
    characteristics: string
    additionalEquipment: string | null
    specialFeatures: string
    arrival: string
    leisure: string
    topicDescriptions: TopicDescription[]
}

export interface Location {
    latitude: number
    longitude: number
}

export interface Surroundings {
    options: string[]
    locations: SurroundingLocation[]
}

export interface PropertyLocationDetails {
    address: AddressTransport
    location: Location
    hideExactPosition: boolean
    surroundings: Surroundings
}

export interface Image {
    url: string
    title: string
    season: string
    sortOrder: number
}

export interface PropertyImages {
    images: Image[]
}

export interface PropertyDetailsRatingSummary {
    average: number
    count: number
    recommendations: number
    features: number
    price: number
    service: number
    ambience: number
}
export interface PropertyDetailsRatingsTransport {
    summary: PropertyDetailsRatingSummary
    ratings: RatingDetails[]
}

export interface Rating {
    average: number
    features: number
    price: number
    service: number
    ambience: number
}

export interface Contact {
    title: number
    customerAlias: string | null
    lastName: string
    firstName: string | null
    email: string
    company: string | null
    communications: any[]
    address: AddressTransport
    spokenLanguages: any[]
    imageId: string | null
    operatingHours: string | null
    introduction: string | null
    website: string | null
    imageUrl: string | null
}

export interface RatingAnswer {
    date: Date
    text: string
}
export interface RatingDetails {
    text: string
    title: string
    contact: Contact
    touristType: number
    travelDateFrom: string
    travelDateTo: string
    ratingDate: string
    rating: Rating
    answers: RatingAnswer[]
}

export interface PropertyBed {
    label: string
    size: string
    count: number
}

export interface PropertyRoom {
    label: string
    squareMeters: number
    count: number
    area: number
    position: string
    type: number
    equipments: string[]
    beds: PropertyBed[]
}

export interface PropertyRooms {
    rooms: PropertyRoom[]
}

export interface PropertyDetailsActivities {
    activities: string[]
}

export interface Suitable {
    suitable: number
    status: string
}

export type PropertyDetailsSuitables = Array<Suitable>

export type PropertyDetailFeatures = string[]

export interface PropertyDetailFeaturesTransport {
    features: PropertyDetailFeatures
}

export interface Deposit {
    type: number
    value: number
}
export interface Arrival {
    days: number[]
    earliestTime: number
}

export interface Departure {
    latestTime: number
}

export interface PrepaymentPercentage {
    value: string
    prePayment: number
    finalPayment: number
    prePaymentDays?: number | null
    finalPaymentDays?: number | null
}

export interface PrepaymentFlatrate {
    prePayment: number | null
    finalPayment: number | null
    prePaymentDays: number | null
    finalPaymentDays: number | null
    value: number
}

export interface Payment {
    options: number[]
    method: number
    prepaymentFlatrate?: PrepaymentFlatrate | null
    prepaymentPercentage: PrepaymentPercentage | null
    totalPayment?: number | null
}

export interface PropertyRentNotes {
    cancellationConditions: string
    notes: string
    deposit: Deposit
    arrival: Arrival
    departure: Departure
    payment: Payment | null
}

export interface PropertyOffersTransport {
    types: OfferTypes[]
    dateFrom: string
    dateTo: string
    discount: number
    nightPrice: number
}

export interface ObjectImprintTransport {
    registrationNumber?: string
    commercialRegister?: string
    vatId?: string
    additionalInfos?: string
    contact?: Contact
}

export interface ExposeTransport {
    objectGeneral: PropertyDetails
    objectContact: CustomerDetails
    objectDescription: PropertyOverview
    objectDestinations: any
    objectLocation: PropertyLocationDetails
    objectRatings: PropertyDetailsRatingsTransport
    objectRooms: PropertyRooms
    objectSuitables: PropertyDetailsSuitables
    objectFeatures: PropertyDetailFeaturesTransport
    objectActivities: PropertyDetailsActivities
    objectImages: PropertyImages
    objectOffer: PropertyOffersTransport | null
    objectRentNotes: PropertyRentNotes
    objectImprint: ObjectImprintTransport
}

export enum CalendarDayState {
    AVAILABLE = 'available',
    BOOKED = 'booked',
    AVAILABLE_BOOKED = 'available_booked',
    BOOKED_AVAILABLE = 'booked_available',
}

export interface CalendarDay {
    state: CalendarDayState
    value: number
}

type Week = CalendarDay[]

export interface MonthData {
    label: string
    month: number
    year: number
    rows: Array<Week>
}

export interface PropertyDetailsAvailabilityTransport {
    result: Record<string, MonthData>
    firstAvailableMonth: string
}

export interface PropertyObjectTransport {
    bedrooms: Bedrooms
    propertyTypeId: number
    title: string
    providerId: PropertyDetails['providerId']
    externalObjectId: PropertyDetails['externalObjectId']
    tfwObjectId?: PropertyDetails['tfwObjectId']
    images: Image[]
    location: Location
}

export interface CustomerObjectsTransport {
    total: number
    objects: PropertyObjectTransport[]
}

export interface RegionNodeTransport {
    id: number
    regionId: number
    regionType: number
    title: string
    link: string
    isMain: boolean
    hasLandingPage: boolean
    depth: number
    vacationType: number
    vacationNodeId: number
    preposition: string
    estimatedObjectCount: number
    updatedAt: string
}

export interface VacationRegionNodeTransport {
    id: number
    regionId: number
    title: string
    titleMisspellings: string[]
    isMain: boolean
    link: string
    regionType: number
    hasLandingPage: boolean
    depth: number
    vacationType: number
    vacationNodeId: number
    preposition: string
    estimatedObjectCount: number
    updatedAt: string
    oldLinks: string[]
}

export interface PriceDetailsSchemaTransport {
    price: string
    isPriceNumeric: boolean
    unit?: string
    persons?: string
    notFormattedPrice?: number | null
}

interface PropertyDetailsStaticDataTransport {
    navigationSection: NavigationSectionTransport
    footerSection: FooterSectionTransport
}

export interface BreadcrumbTransport {
    type: BreadcrumbTypeEnum
    link?: string
    isRegionLink: boolean
    label?: string
}

interface PropertyDetailsPageTransport extends PageTransport {
    head: HeadTransport
    staticData: PropertyDetailsStaticDataTransport
    object?: PropertyObjectTransport
    customerObjects: CustomerObjectsTransport
    regionNode: RegionNodeTransport
    expose: ExposeTransport
    availabilityCalendar: PropertyDetailsAvailabilityTransport
    price: PriceDetailsSchemaTransport
    vacationRegionNode: VacationRegionNodeTransport
    breadcrumbs: BreadcrumbTransport[]
}

export default PropertyDetailsPageTransport
