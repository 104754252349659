import React, { FC, memo } from 'react'
import Price from '../price'
import { DottedSeparator } from '../style'
import { SideBarPaddingWrapper } from './styles'
import OwnerInfo from './owner-info'
import { DatePickerAlignment } from '../../common/date-picker/types'
import { FormattedContactDetails } from '../../../models/property-details-page/contact-model'
import { SearchbarFieldsReturn, UpdateSearchBarTransport } from '../hooks/use-searchbar'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { FormattedOfferTransport } from '../../../models/property-details-page/offers-model'
import { FormattedLivePricingDataTransport } from '../../../models/pricing/live-pricing-formatter'
import { PropertyDetails } from '../../../transports/property-details-page-transport'

type SidebarProps = {
    trust?: Record<string, string | null>
    contact: FormattedContactDetails
    searchbar: SearchbarFieldsReturn
    updateSearchbar: UpdateSearchBarTransport
    availableDates: Date[] | null
    isAvailabilityLoading: boolean
    errorFetchingAvailability: Error | null
    pricingDetails: FormattedLivePricingDataTransport | null
    isPricingLoading: boolean
    pricingErrorCode: number | null
    bookingType: FormattedBookingTypeData | null
    offers: FormattedOfferTransport | null
    showVatTax: boolean
    providerId: PropertyDetails['providerId']
    isTraumPaymentEnabled: boolean
    showCalendar: boolean
}

const Sidebar: FC<SidebarProps> = ({
    trust,
    contact,
    searchbar,
    updateSearchbar,
    availableDates,
    isAvailabilityLoading,
    pricingDetails,
    pricingErrorCode,
    isPricingLoading,
    bookingType,
    offers,
    showVatTax,
    providerId,
    isTraumPaymentEnabled,
    showCalendar,
}) => {
    return (
        <>
            <SideBarPaddingWrapper>
                <Price
                    datePickerAlignment={DatePickerAlignment.RIGHT}
                    layout="stretched"
                    searchbar={searchbar}
                    updateSearchbar={updateSearchbar}
                    availableDates={availableDates}
                    isAvailabilityLoading={isAvailabilityLoading}
                    pricingDetails={pricingDetails}
                    pricingErrorCode={pricingErrorCode}
                    isPricingLoading={isPricingLoading}
                    bookingType={bookingType}
                    offers={offers}
                    showVatTax={showVatTax}
                    isTraumPaymentEnabled={isTraumPaymentEnabled}
                    showCalendar={showCalendar}
                    position="sidebar"
                />
            </SideBarPaddingWrapper>
            <DottedSeparator />
            <OwnerInfo trust={trust} contact={contact} providerId={providerId} />
        </>
    )
}

export default memo(Sidebar)
